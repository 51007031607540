import React from 'react';
import './App.css';
import logo from './assets/images/kaleblogo.png';
import { Grid, Button } from '@material-ui/core';
import SelectAmountDialog from './SelectAmount';
import { round } from 'lodash';

function App() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<number | undefined>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: number) => {
    setOpen(false);
    setSelectedValue(value);
  };

  React.useEffect( () => {
    if (selectedValue) {
      document.location.href = `https://aanmelden.kaleb.nl/mollie/donatie/${round(selectedValue * 100)}`
      console.log("Amount selected", selectedValue);
    }
  }, [selectedValue]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item className="header">
        <img className="logo" alt="logo" src={logo} />
      </Grid>
      <Grid item>
        <p>Selecteer het bedrag dat u wilt doneren en volg de betaalinstructies op het volgende scherm.</p>
      </Grid>
      <Grid item>
        <Button className="button" variant="contained" color="primary" onClick={() => setSelectedValue(10)}>Doneer €10,-</Button>
      </Grid>
      <Grid item>
        <Button className="button" variant="contained" color="primary" onClick={() => setSelectedValue(25)}>Doneer €25,-</Button>
      </Grid>
      <Grid item>
      <Button className="button" variant="contained" color="primary" onClick={handleClickOpen}>Selecteer ander bedrag</Button>
      </Grid>
      <SelectAmountDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </Grid>
  );
}

export default App;
